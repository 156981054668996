import { create } from 'zustand';

import { persist } from 'zustand/middleware';

import { immer } from 'zustand/middleware/immer';

import { Session } from 'types/session';

type State = {
  isAuth: boolean;
  session: Session | null;
};

const useSessionStore = create(
  persist(
    immer<State>(() => ({
      session: null,
      isAuth: false,
    })),
    {
      name: 'rturn-partners-portal-session',
    },
  ),
);

const selectors = {
  useIsAuth: () => useSessionStore((state) => state.isAuth),
  useUsername: () => useSessionStore((state) => state.session?.username || ''),
};

const actions = {
  setSession: (session: Session) => {
    const set = useSessionStore.setState;

    set((state) => {
      state.session = session;
      state.isAuth = true;
    });
  },
  clearSession: () => {
    const set = useSessionStore.setState;

    set((state) => {
      state.session = null;
      state.isAuth = false;
    });
  },

  // getters
  getIsAuth: () => useSessionStore.getState().isAuth,
  getSession: () => useSessionStore.getState().session,
  getTokens: () => useSessionStore.getState().session?.tokens,
};

/**
 * Session store
 *
 * $ sign is used to indicate that this is a store
 */
export const $session = {
  actions,
  selectors,
};
