import api from './instance';

import { SKU } from 'types/sku';
import { ImpactReport, Portal } from 'types/groups';
import { Order } from 'types/order';
import { FetchResponse } from 'types/common';

const URL = `${process.env.REACT_APP_API_URL}`;

export const fetchPortalDetails = async () => {
  const { data } = await api.get(`${URL}/contact/partner-portal/details`);

  return data?.item as Portal;
};

export const fetchSkus = async (site: string) => {
  const { data } = await api.get(`${URL}/contact/sku-details/${site}`);

  return data?.item?.packages as SKU[];
};

type CreateOrderPayload = {
  details: Array<{
    casesCount: number;
    sku: string;
  }>;
  notes: string;
  site: string;
};

export const createOrder = async (
  payload: CreateOrderPayload,
  portal: string,
) => {
  const { data } = await api.post(
    `${URL}/create-request-order/${portal}`,
    payload,
  );

  return data?.item as Order;
};

export const fetchOrder = async (portal: string, order: string) => {
  const { data } = await api.get(`${URL}/order-details/${portal}/${order}`);

  return data?.item?.[0] as Order;
};

export const createBulkOrder = async (payload: any) => {
  const { data } = await api.post(
    `${URL}/order/create-partner-order-request`,
    payload,
  );

  return data;
};

type GetImpactReportPayload = {
  site: string[];
  dateFilters: Array<{
    month: number;
    year: number;
  }>;
};

export const getImpactReport = async (payload: GetImpactReportPayload) => {
  const { data } = await api.post(
    `${URL}/report/generate-impact-reports`,
    payload,
  );

  return data?.item?.map((i: any) => i?.impact) as ImpactReport[];
};

type Params = {
  sort: string;
  page: number;
  limit: number;
  venue: string[];
};

export const getOrderHistory = async (params: Params) => {
  const { data } = await api.get<FetchResponse<Order>>(
    `${URL}/order/partner/order-history`,
    {
      params,
    },
  );

  return data;
};
