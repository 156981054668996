import { Site } from './site';
import { SKU } from './sku';
import { Location } from './washhub';
import { Customer } from './user';

export enum Status {
  Scheduled,
  Packed,
  Delivered,
  Returned,
  Washed,
  Dry,
  Unused,
  Ready,
  Missing,
  Lost,
  Decommissioned,
  Damaged,
  Close,
  Pending_Count,
  Empty_Bin,
  Requested,
  Rejected,
}
export enum DateStatus {
  TODAY,
  TOMORROW,
  OVERDUE,
  FUTURE,
}

export type Item = {
  _id: string;
  createdAt: Date;
  updatedAt: Date;
  sku: SKU;
  status: Status;
  type: string;
  quantityPerCase: number;
  size: number;
  rfid: string;
  washHub: Location;
  washHubCode: string;
  currentPackage: string;
  lastScanAt: string;
  currentVenue?: Site;
  packageId?: string;
};

export type Case = {
  _id: string;
  createdAt: Date;
  updatedAt: Date;
  sku: SKU;
  status: Status;
  washHub: Location;
  currentPackage?: string;
  currentVenue?: Site;
};

export type Scan = {
  _id: string;
  caseCount: number;
  createdAt: Date;
  itemCount: number;
  scanPerformedBy: Partial<Customer>;
  status: Status;
  updatedAt: Date;
  washHub: string;
  isEPC?: boolean;
  site?: Array<any>;
  itemSku?: Array<SKU>;
  performedAt?: Date;
  scanId?: string;
};
