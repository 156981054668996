import React, { FC, useEffect } from 'react';

import { Flex, Image, Text, Center, Divider, Collapse } from '@chakra-ui/react';
import { MdGroups, MdLocalActivity } from 'react-icons/md';

import logo from 'assets/r.World_new_logo.png';

import { Site } from 'types/site';
import { Portal } from 'types/groups';
import { useHistory } from 'react-router-dom';

type Props = {
  sites: Site[];
  groups: Portal['groups'];
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  selected: Site[];
  setSelected: React.Dispatch<React.SetStateAction<Site[]>>;
};

const SideBar: FC<Props> = ({
  groups,
  sites,
  isOpen,
  onClose,
  onOpen,
  selected,
  setSelected,
}) => {
  const group = groups?.[0];

  const history = useHistory();

  useEffect(() => {
    setSelected((prev) => (prev.length > 0 ? prev : group?.sites || []));
  }, []);

  return (
    <Flex
      minW="280px"
      maxW="280px"
      direction="column"
      bg="#fff"
      shadow="2xl"
      zIndex={1}>
      <Center py="20px">
        <Image src={logo} w="182px" objectFit="contain" />
      </Center>

      <Divider />

      <Flex
        mx="10px"
        p="10px"
        borderRadius="10px"
        borderBottomRadius={isOpen ? '0' : '10px'}
        bg={isOpen ? '#D8EBDF' : '#fff'}
        as="button"
        onClick={() => {
          onOpen();

          setSelected(group?.sites || []);

          if (history.location.pathname !== '/') {
            history.push(`/`, {
              selected: group?.sites || [],
            });
          }
        }}
        color="primary.400"
        gap="20px"
        align="flex-end">
        <MdGroups size="26px" />
        <Text noOfLines={1} fontSize="14px" fontWeight="bold">
          {group?.companyName || ''}
        </Text>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Flex
          mx="10px"
          p="10px"
          direction="column"
          borderRadius="10px"
          borderTopRadius="0"
          bg="#EFFAF3">
          {group?.sites?.map((s) => (
            <Flex
              key={s._id}
              align="flex-end"
              gap="20px"
              as="button"
              color="primary.400">
              <MdLocalActivity size="20px" />
              <Text noOfLines={1} fontSize="12px" fontWeight="bold">
                {s.name || ''}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Collapse>

      <Flex mt="20px" direction="column" gap="5px">
        {sites?.map((s, i) => (
          <Flex
            key={s._id + i}
            align="flex-end"
            gap="20px"
            color="primary.400"
            p="10px"
            mx="10px"
            as="button"
            borderRadius="10px"
            bg={
              !isOpen && selected?.map((s) => s._id).includes(s._id)
                ? '#D8EBDF'
                : '#fff'
            }
            _active={{
              bg: 'primary.900',
              transition: '0.3s ease',
            }}
            onClick={() => {
              onClose();

              setSelected([s]);

              if (history.location.pathname !== '/') {
                history.push(`/`, {
                  selected: [s],
                });
              }
            }}>
            <MdLocalActivity size="26px" />
            <Text noOfLines={1} fontSize="14px" fontWeight="bold">
              {s.name || ''}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default SideBar;
