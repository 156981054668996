import React, { FC, useState } from 'react';

import { Center, SimpleGrid, Spinner, Text } from '@chakra-ui/react';

import { useSkus } from 'queries';

import { useFormikContext } from 'formik';

import FormikInput from 'components/primitives/FormikInput';
import FormikTextArea from 'components/primitives/FormikTextArea';

import {
  Check,
  FormHeader,
  FormHeading,
  FormContent,
  FormContainer,
} from './CreateOrder.style';

import { Site } from 'types/site';

type Props = {
  index: number;
  site: Site;
};

function alphanumericSort(a: any, b: any) {
  return String(a?.name)
    .toLowerCase()
    .localeCompare(String(b?.name).toLowerCase());
}

const OrderForm: FC<Props> = ({ site, index }) => {
  const siteId = site?._id || '';

  const [isChecked, setIsChecked] = useState(false);

  const { values, setFieldValue } = useFormikContext<any>();

  const formValue = values?.items?.[index];

  const { data: skus = [] } = useSkus(siteId);

  const onCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setFieldValue(
        `items[${index}].details`,
        skus?.map((s: any) => ({
          sku: s._id,
          casesCount: '0',
        })),
      );
      setFieldValue(`items[${index}].notes`, 'Skip delivery to this site');

      setIsChecked(true);
    } else {
      setFieldValue(
        `items[${index}].details`,
        skus?.map((s: any) => ({
          sku: s._id,
          casesCount: '0',
        })),
      );
      setFieldValue(`items[${index}].notes`, '');

      setIsChecked(false);
    }
  };

  return (
    <FormContainer>
      {!Boolean(site.packages) ? (
        <Text
          style={{
            fontSize: '16px',
            color: '#4f4f4f',
          }}
          p={10}>
          There are no Packages SKU assigned to this site. Please contact the
          R.turn admin for further assistance.
        </Text>
      ) : (
        <>
          <FormHeader>
            <FormHeading>{site?.name || ''}</FormHeading>
            <Check onChange={onCheckChange}>
              I don’t need an order for this site
            </Check>
          </FormHeader>
          {isChecked ? null : (
            <FormContent>
              <SimpleGrid
                mb="20px"
                flexWrap="wrap"
                columns={[1, 1, 3]}
                spacing={4}>
                {skus?.sort(alphanumericSort)?.map((p: any, ind: number) => (
                  <FormikInput
                    defaultValue=""
                    key={JSON.stringify(p) + index + ind}
                    name={`items[${index}].details[${ind}].casesCount`}
                    label={`Cases of ${p?.name || ''}`}
                    helperText={p?.description || ''}
                    type="number"
                    labelProps={{
                      fontSize: '14px',
                      color: '#4f4f4f',
                      fontWeight: '600',
                    }}
                    pattern="[0-9]*"
                    inputMode="numeric"
                  />
                ))}
                <FormikInput
                  name="totalCount"
                  label="Total Cases"
                  isReadOnly
                  value={formValue?.details
                    ?.reduce(
                      (acc: any, a: any) => acc + parseInt(a.casesCount || '0'),
                      0,
                    )
                    .toString()}
                  labelProps={{
                    fontSize: '14px',
                    color: '#4f4f4f',
                    fontWeight: '600',
                  }}
                />
              </SimpleGrid>

              <FormikTextArea
                name={`items[${index}].notes`}
                label="Notes, Questions, Feedback"
                minH="120px"
                labelProps={{
                  fontSize: '14px',
                  color: '#4f4f4f',
                }}
              />
            </FormContent>
          )}
        </>
      )}
    </FormContainer>
  );
};

export default OrderForm;
