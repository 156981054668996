import React, { useEffect } from 'react';

import { useToast } from '@chakra-ui/react';

import { login } from 'api/auth';
import { useMutation } from 'react-query';

import { $session } from 'stores';

import { LoginProps } from './Login.props';
import { CredentialResponse } from '@react-oauth/google';

import LoginView from './Login.view';

const LoginContainer = (props: LoginProps) => {
  const toast = useToast();

  const { from } = props.location.state || { from: { pathname: '/' } };

  useEffect(() => {
    const isAuth = $session.actions.getIsAuth();

    if (isAuth) {
      props.history.replace('/');
    }
  }, []);

  const googleLoginMtn = useMutation(login, {
    onSuccess: (session) => {
      $session.actions.setSession(session);

      props.history.replace(from);
    },
  });

  const onSuccess = (credentialResponse: CredentialResponse) => {
    const token = credentialResponse.credential;

    if (token) {
      googleLoginMtn.mutate(token);
    }
  };

  const onError = () => {
    toast({
      title: 'Error',
      status: 'error',
      duration: 5000,
      isClosable: true,
      description: 'Something went wrong',
    });
  };

  return (
    <LoginView
      onError={onError}
      onSuccess={onSuccess}
      isLoading={googleLoginMtn.isLoading}
    />
  );
};

export default LoginContainer;
