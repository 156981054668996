import React, { FC, useState } from 'react';

import { useAllSkus, usePortal } from 'queries';

import { useQuery } from 'react-query';
import { getOrderHistory } from 'api/portal';

import { OrderHistoryProps } from './OrderHistory.props';

import OrderHistoryView from './OrderHistory.view';

const OrderHistoryContainer: FC<OrderHistoryProps> = ({
  selected,
  setSelected,
}) => {
  const { data: portal, isLoading: isLoadingPortal } = usePortal();

  const [page, setPage] = useState(1);

  const { data, isLoading } = useQuery(
    [`fetch-order-history`, selected?.map((s) => s._id), page],
    () =>
      getOrderHistory({
        limit: 10,
        page,
        sort: '-1',
        venue: selected?.map((s) => s._id),
      }),
    {
      enabled: selected.length > 0,
    },
  );

  const orders = data?.items || [];
  const totalCount = data?.total || 0;
  const totalPages = data?.totalPages || 0;

  const pagination = {
    page,
    setPage,
    totalCount,
    totalPages,
  };

  const { allSkus } = useAllSkus(
    orders?.map((i) =>
      typeof i.site == 'string'
        ? ({ _id: i.site } as any)
        : ({ _id: i.site._id } as any),
    ),
  );

  return (
    <OrderHistoryView
      orders={orders}
      allSkus={allSkus}
      portal={portal}
      selected={selected}
      isLoading={isLoading}
      pagination={pagination}
      setSelected={setSelected}
      isLoadingPortal={isLoadingPortal}
    />
  );
};

export default OrderHistoryContainer;
