import { useQuery } from 'react-query';

import { fetchSkus } from 'api/portal';

const useSkus = (site: string, onSuccess?: (data: any) => void) => {
  const queryKey = ['skus', site];
  const queryFn = () => fetchSkus(site);
  const options = {
    enabled: !!site,
    onSuccess,
  };

  const query = useQuery(queryKey, queryFn, options);

  return query;
};

export default useSkus;
