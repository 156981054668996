import React from 'react';

import { useAllSkus } from 'queries';

import { OrderDetailsProps } from './OrderDetails.props';

import OrderDetailsView from './OrderDetails.view';

const OrderDetailsContainer = (props: OrderDetailsProps) => {
  const items =
    (props.location.state.items?.map((i) => ({
      ...i,
      site: typeof i.site === 'string' ? i.site : i?.site?._id,
    })) as any[]) || [];

  const { allSkus, isLoading } = useAllSkus(
    items?.map((i) =>
      typeof i.site == 'string'
        ? ({ _id: i.site } as any)
        : ({ _id: i.site._id } as any),
    ),
  );

  return (
    <OrderDetailsView
      items={items}
      allSkus={allSkus}
      isLoading={isLoading}
      goBack={props.history.goBack}
    />
  );
};

export default OrderDetailsContainer;
