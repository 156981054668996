import { Flex, Image } from '@chakra-ui/react';

import { chakraStyled } from 'utils';

export const Container = chakraStyled(Flex, {
  px: '10',
  py: '5',
  shadow: 'lg',
  zIndex: 5,
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const Logo = chakraStyled(Image, {
  maxW: '100px',
  cursor: 'pointer',
});
