import { useQueries } from 'react-query';

import { fetchSkus } from 'api/portal';

import { Site } from 'types/site';

const useAllSkus = (sites: Site[]) => {
  const allQueries = useQueries(
    sites?.map((s) => {
      return {
        queryKey: ['skus', s._id],
        queryFn: () => fetchSkus(s._id),
      };
    }),
  );

  const isLoading = allQueries.some((q) => q.isLoading);

  const allSkus = allQueries?.map((q, i) => {
    return {
      site: sites[i]._id || '',
      skus: q.data || [],
    };
  });

  return { allSkus, isLoading };
};

export default useAllSkus;
