import React, { FC, memo } from 'react';

import { UserPillProps } from './UserPill.props';

import UserPillView from './UserPill.view';

const Container: FC<UserPillProps> = (props) => {
  return <UserPillView {...props} />;
};

const UserPill = memo(Container);

export default UserPill;
