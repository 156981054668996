import React, { FC } from 'react';

import {
  Center,
  Flex,
  Image,
  Spinner,
  Stack,
  Text,
  chakra,
  Link,
} from '@chakra-ui/react';

import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

import rTurn from 'assets/logo.png';
import loginBg from 'assets/login_bg.png';

import { LoginViewProps } from './Login.props';
import { MicrosoftSignInButton } from 'components/modules/MicrosoftSignInButton';

const Span = chakra('span');

const CLIENT_ID = String(process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID);

const LoginView: FC<LoginViewProps> = ({ isLoading, onError, onSuccess }) => {
  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <Flex
        w="100%"
        bgImage={`url(${loginBg})`}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover">
        <Center flex={1} gap="50px" bg="transparent" flexWrap="wrap">
          <Stack align="center">
            <Image src={rTurn} h="80px" w="auto" objectFit="contain" />
            <Text fontSize="24px" fontWeight="bold">
              Be a Zero-Waste-Hero
            </Text>
          </Stack>
          <Stack
            m="20px"
            minHeight="400px"
            align="center"
            justify="center"
            p="22px"
            spacing={6}
            shadow="lg"
            borderRadius="20px"
            bg="#f3fade">
            <Text fontSize="30px" fontWeight="bold" textAlign="center">
              Welcome, nice to see you!
            </Text>
            <Text textAlign="center">Login to continue</Text>
            <Flex position="relative" justify="center" align="center">
              <Flex direction="column" align="center" gap="10px">
                <GoogleLogin
                  onError={onError}
                  onSuccess={onSuccess}
                  width="200px"
                />
                <MicrosoftSignInButton />
              </Flex>
              {isLoading && (
                <Flex
                  justify="center"
                  align="center"
                  w="100%"
                  position="absolute"
                  bg="#ffffff90">
                  <Spinner />
                </Flex>
              )}
            </Flex>
            <Text fontSize="13px">
              If you need help,{' '}
              <Span color="#19B739" as={Link}>
                Contact Us
              </Span>
            </Text>
          </Stack>
        </Center>
      </Flex>
    </GoogleOAuthProvider>
  );
};

export default LoginView;
