import { SKU } from 'types/sku';
import { Site } from 'types/site';

export const getInitialValues = (
  sites: Site[],
  allSkus: Array<{
    site: string;
    skus: SKU[];
  }>,
) => ({
  items: sites.map((s) => {
    const _sku = allSkus?.find((sku) => sku.site === s._id)?.skus || [];

    return {
      details: _sku?.map((sku) => ({
        sku: sku._id,
        casesCount: '0',
      })),
      site: s._id,
      notes: '',
    };
  }),
});
