import React, { FC } from 'react';

// import rTurn from 'assets/logo.png';

import { Text } from '@chakra-ui/react';

import { UserPill } from '../UserPill';

import { NavBarProps } from './NavBar.props';

import { Logo, Container } from './NavBar.style';

const NavBarView: FC<NavBarProps> = ({ groupName }) => {
  return (
    <Container>
      {Boolean(groupName) ? (
        <Text fontWeight="600" fontSize="22px">
          {groupName}
        </Text>
      ) : (
        <Logo />
      )}
      <UserPill />
    </Container>
  );
};

export default NavBarView;
