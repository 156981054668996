import React, { FC } from 'react';

import { FaWindows } from 'react-icons/fa';

import { Button, Text } from '@chakra-ui/react';

import { useMsal } from '@azure/msal-react';

import { loginRequest } from 'utils/msalConfig';

import { MicrosoftSignInButtonProps } from './MicrosoftSignInButton.props';

const MicrosoftSignInButton: FC<MicrosoftSignInButtonProps> = (props) => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  return (
    <Button
      w="200px"
      borderRadius="3px"
      rightIcon={<FaWindows size="16px" />}
      colorScheme="blue"
      fontSize="12px"
      {...props}
      onClick={() => handleLogin()}>
      <Text flex={1}>Sign In with Microsoft</Text>
    </Button>
  );
};

export default MicrosoftSignInButton;
