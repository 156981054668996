import { format } from 'date-fns';

export const formatedDate = (
  dateToFormat: Date | undefined,
  formatInUTC: boolean = false,
  onlyDate: boolean = false,
) => {
  let toFormatDate = ' - ';
  if (!Boolean(dateToFormat)) return toFormatDate;
  toFormatDate =
    typeof dateToFormat === 'undefined'
      ? ' - '
      : dateToFormat?.toLocaleString('en-US', { timeZone: 'CT' });

  if (formatInUTC) {
    return new Date(toFormatDate).toLocaleString('en-US', {
      timeZone: 'UTC',
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    });
  }

  const dateFormat = onlyDate ? 'MM/dd/yyyy' : 'MM/dd/yyyy hh:mm aa';

  return `${format(new Date(toFormatDate), dateFormat)}`;
};
