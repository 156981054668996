import { Box, Center, Flex, Image, Text } from '@chakra-ui/react';

import { chakraStyled } from 'utils';

import bg from 'assets/bg.png';
import logo from 'assets/rcup-white.png';
import banner from 'assets/cms-bg-preview.png';

export const Container = chakraStyled(Flex, {
  flex: 1,
  maxH: '100vh',
  overflowY: 'auto',
  flexDirection: 'column',
  bg: '#fff',
  zIndex: 0,
});

export const HeaderContainer = chakraStyled(Flex, {
  position: 'relative',
  direction: 'column',
});

export const Banner = chakraStyled(Image, {
  h: '200px',
  w: '100%',
  objectFit: 'cover',
  zIndex: 1,
  src: banner,
});

export const InfoContainer = chakraStyled(Flex, {
  bg: 'transparent',
  direction: { base: 'column', md: 'row' },
  justifyContent: 'space-between',
  w: '100%',
  pr: { base: '0', md: '20px' },
  mt: '20px',
});

export const LogoContainer = chakraStyled(Box, {
  h: '160px',
  minW: '160px',
  bg: '#fff',
  mb: { base: 0, md: 0 },
  ml: { base: 0, md: '100px' },
  borderRadius: '20px',
  overflow: 'hidden',
  boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.2)',
});

export const Logo = chakraStyled(Image, {
  h: '100px',
  w: '100px',
  objectFit: 'contain',
});

export const Name = chakraStyled(Text, {
  mb: 2,
  fontSize: '26px',
  fontWeight: 'bold',
  ml: { base: 0, md: 10 },
  textAlign: { base: 'center', md: 'left' },
  alignSelf: { base: 'center', md: 'flex-end' },
});

export const Section = chakraStyled(Flex, {
  alignItems: { base: 'center', md: 'flex-end' },
  alignSelf: { base: 'center', md: 'flex-end' },
  direction: { base: 'column', md: 'row' },
});

export const GridContainer = chakraStyled(Flex, {
  flex: 1,
  p: { base: '15px', md: '50px' },
  direction: 'column',
  fontSize: '18px',
  fontWeight: '600',
});

export const Row = chakraStyled(Flex, {
  w: '100%',
  p: '20px',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Content = chakraStyled(Flex, {
  flex: 1,
  bg: '#fff',
  direction: 'row',
  fontSize: '18px',
  p: '15px',
});

export const Footer = chakraStyled(Flex, {
  mt: '20px',
  p: '20px',
  minH: '100px',
  bg: '#E7F5EA',
  w: '100%',
});

export const Column = chakraStyled(Flex, {
  gap: '10px',
  direction: 'column',
});

export const Label = chakraStyled(Text, {
  fontSize: '16px',
  fontWeight: '600',
});

export const Value = chakraStyled(Text, {
  fontSize: '32px',
  fontWeight: 'bold',
  bg: 'primary.900',
  p: '5px 15px',
});

export const PdfContainer = chakraStyled(Flex, {
  h: '632px',
  w: '446px',
  p: '25px 20px',
  zIndex: -1,
  bg: '#50A96F',
  position: 'absolute',
  direction: 'column',
  backgroundImage: bg,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundBlendMode: 'difference',
  transform: 'scale(1.2)',
});

export const HeaderRow = chakraStyled(Flex, {
  w: '100%',
  color: '#fff',
  fontSize: '12px',
  fontWeight: 'bold',
  justify: 'space-between',
  align: 'flex-end',
});

export const RturnLogo = chakraStyled(Image, {
  src: logo,
  w: '100px',
  objectFit: 'contain',
  mb: '-5px',
});

export const ContentContainer = chakraStyled(Flex, {
  flex: 1,
  mt: '20px',
  bg: '#fff',
  direction: 'column',
  borderRadius: '20px',
  gap: '10px',
  px: '20px',
});

export const SiteLogo = chakraStyled(Image, {
  h: '50px',
  mt: '10px',
  w: '50px',
  overflow: 'hidden',
  alignSelf: 'center',
  objectFit: 'cover',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ItemContainer = chakraStyled(Flex, {
  gap: '10px',
  flex: 1,
  overflow: 'hidden',
  alignSelf: 'center',
});

export const ItemIcon = chakraStyled(Image, {
  borderRadius: 'md',
  objectFit: 'contain',
  userSelect: 'none',
  draggable: false,
  maxH: '100px',
  maxW: '50px',
});

export const ValuContainer = chakraStyled(Center, {
  bg: '#E0F5DD',
  color: '#377464',
});

export const ItemLabel = chakraStyled(Text, {
  fontSize: '10px',
  fontWeight: 'bold',
  pb: '10px',
});

export const ItemName = chakraStyled(Text, {
  fontSize: '18x',
  fontWeight: 'bold',
  textAlign: 'center',
  pb: '10px',
  flexWrap: 'wrap',
});

export const ItemDate = chakraStyled(Text, {
  fontSize: '18x',
  fontWeight: 'bold',
  textAlign: 'end',
  pb: '10px',
  flexWrap: 'wrap',
});

export const ItemValue = chakraStyled(Text, {
  fontSize: '12px',
  fontWeight: 'bold',
  textAlign: 'center',
  pt: '5px',
  pb: '15px',
});

export const FooterText = chakraStyled(Text, {
  mt: '20px',
  color: '#fff',
  fontSize: '12px',
  fontWeight: 'bold',
  textAlign: 'center',
});
