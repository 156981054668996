import React, { FC, useMemo, useState } from 'react';

import {
  Flex,
  Image,
  Button,
  Center,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';

import axios from 'axios';

import { jsPDF } from 'jspdf';

import html2canvas from 'html2canvas';

import banner from 'assets/cms-bg-preview.png';

import { useHistory } from 'react-router-dom';

import PdfContent from './PdfContent';
import NavBar from 'components/core/NavBar';
import SideBar from '../CreateOrder/SideBar';
import OrderHistory from 'components/modules/OrderHistory';
import SlideTabs from 'components/primitives/SlideTabs/SlideTabs';
import EnvironmentTable from './EnvironmentTab/EnvironmentTable';

import { Container } from './Dashboard.style';

import { DashboardViewProps } from './Dashboard.props';

const DashboardView: FC<DashboardViewProps> = ({
  report,
  portal,
  isLoading,
  filter,
  selected,
  setFilter,
  setSelected,
  isLoadingReport,
  monthAndYear,
}) => {
  const history = useHistory();

  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: selected?.length > 1,
  });
  const [slidePosition, setSlidePosition] = useState(1);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadPdf = (logoURL: string) => async () => {
    function downloadPDF() {
      const grid = document.getElementById('impact-report-grid');

      if (!grid) {
        return;
      }

      return html2canvas(grid, {
        useCORS: true,
        allowTaint: true,
      }).then((canvas) => {
        const pdf = new jsPDF('p', 'px', '');

        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = pdf.internal.pageSize.getHeight();

        pdf.addImage(canvas, 'PNG', 0, 0, imgWidth, imgHeight);

        const fileName =
          selected?.length > 1
            ? `group-env-impact-report-${
                selected?.map((s) => s?.name)?.join('-') || ''
              }`
            : `group-env-impact-report-${selected?.[0]?.name || ''}`;

        pdf.save(`${fileName}.pdf`);
      });
    }

    try {
      if (Boolean(logoURL)) {
        setIsDownloading(true);

        const response = await axios.get(logoURL, {
          responseType: 'blob',
          headers: {
            Accept: 'application/octet-stream',
            'Content-Type': 'application/octet-stream',
            'Access-Control-Allow-Origin': '*',
            'Accept-Language': 'en-GB,en-US;q=0.9,en;q=0.8',
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
          },
        });

        const blob = new Blob([response.data]);

        logoURL = URL.createObjectURL(blob);

        const logoEl = document.getElementById('site-logo') as HTMLImageElement;

        if (logoEl) {
          logoEl.src = logoURL;
        }
      }

      await downloadPDF();

      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
    }
  };

  const groupName =
    selected?.length > 1
      ? portal?.groups?.[0]?.companyName || ''
      : selected?.[0]?.name || '';

  const selectedSites = selected.map((s) => s._id).toString();

  const logo = useMemo(() => {
    const imageURLs = portal?.imageURLs;

    if (selected.length === 1) {
      const _site = imageURLs?.sitesImages?.find(
        (s) => s.site === selected[0]._id,
      );

      return _site?.logo || '';
    }

    return '';
  }, [selectedSites]);

  return (
    <>
      {report && (
        <Flex overflow="hidden" position="relative">
          <PdfContent
            logo={logo}
            report={report}
            monthAndYear={monthAndYear}
            siteName={selected[0].name}
          />
        </Flex>
      )}
      <Flex flex={1}>
        {isLoading ? (
          <Center flex={1}>
            <Spinner size="xl" />
          </Center>
        ) : (
          <>
            <SideBar
              sites={portal?.sites || []}
              groups={portal?.groups || []}
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              selected={selected}
              setSelected={setSelected}
            />
            <Container>
              <NavBar groupName={groupName} />
              {selected?.length > 1 ? (
                <Image
                  src={portal?.imageURLs?.groupImage.banner || banner}
                  h="200px"
                  w="100%"
                  objectFit="cover"
                  zIndex={1}
                />
              ) : (
                <>
                  {portal?.imageURLs?.sitesImages
                    .filter((item) => {
                      return item?.site === selected[0]?._id;
                    })
                    .map((item) => {
                      return (
                        <Image
                          src={item?.banner.length > 0 ? item.banner : banner} // Use item.banner if it exists, otherwise use the fallback banner
                          h="200px"
                          w="100%"
                          objectFit="cover"
                          zIndex={1}
                          key={item?.site} // Assuming there's a unique identifier for each item
                        />
                      );
                    })}
                  {portal?.imageURLs?.sitesImages.filter(
                    (item) => item?.site === selected[0]?._id,
                  ).length === 0 && (
                    <Image
                      src={banner} // Provide the source for your default image
                      h="200px"
                      w="100%"
                      objectFit="cover"
                      zIndex={1}
                    />
                  )}
                </>
              )}
              <Flex align={'center'} justifyContent={'space-between'} px={5}>
                <SlideTabs
                  setSlidePosition={setSlidePosition}
                  slidePosition={slidePosition}
                />
                {slidePosition === 0 && (
                  <Button
                    onClick={() =>
                      history.push('/create', {
                        selected,
                      })
                    }>
                    Create Order
                  </Button>
                )}
              </Flex>
              {slidePosition === 0 ? (
                <OrderHistory selected={selected} setSelected={setSelected} />
              ) : (
                <EnvironmentTable
                  handleDownloadPdf={handleDownloadPdf(logo)}
                  setFilter={setFilter}
                  filter={filter}
                  report={report}
                  isLoadingReport={isLoadingReport}
                  isDownloading={isDownloading}
                />
              )}
            </Container>
          </>
        )}
      </Flex>
    </>
  );
};

export default DashboardView;
