import axios from 'axios';

import { $session } from 'stores';

import createAuthRefreshInterceptor from 'axios-auth-refresh';

const URL = `${process.env.REACT_APP_API_URL}`;

const DEFAULT_HEADERS: Record<string, string> = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const instance = axios.create({
  baseURL: URL,
  headers: DEFAULT_HEADERS,
});

instance.interceptors.request.use((request) => {
  const tokens = $session.actions.getTokens();
  const jwtToken = tokens?.jwtToken || '';

  if (jwtToken) {
    request.headers.Authorization = `Bearer ${jwtToken}`;
  }

  return request;
});

const refreshAuthToken = async (payload: {
  username: string;
  refreshToken: string;
}) => {
  const { data } = await axios.post(
    `${URL}/auth/partner-portal/refresh-token`,
    payload,
  );

  return data;
};

const refreshTokenLogic = async (callbackRequest: any) => {
  try {
    if (callbackRequest.response.data.code === 'expired_token') {
      const session = $session.actions.getSession();

      if (!session) {
        return Promise.reject({
          response: {
            data: {
              message: 'Please Log in',
            },
          },
        });
      }

      const username = session.username;
      const refreshToken = session.tokens.refreshToken;

      const data = await refreshAuthToken({
        username,
        refreshToken,
      })
        .then((data) => data)
        .catch(() => null);

      if (!data) {
        return Promise.reject({
          response: {
            data: {
              message: 'Your session has expired. Please Log in again.',
            },
          },
        });
      }

      $session.actions.setSession(data.item);

      callbackRequest.response.config.headers[
        'Authorization'
      ] = `Bearer ${data.item.tokens.jwtToken}`;

      return Promise.resolve();
    } else {
      $session.actions.clearSession();

      const message = callbackRequest.response?.data;

      return Promise.reject(message);
    }
  } catch (error: any) {
    throw new Error(error);
  }
};

const options = { statusCodes: [401] };

createAuthRefreshInterceptor(instance, refreshTokenLogic, options);

export default instance;
