import api from './instance';

import { Session } from 'types/session';

export const login = async (token: string) => {
  const { data } = await api.post('/auth/partner-portal/google', {
    token,
  });

  return data?.item as Session;
};

export const azureLogin = async (token: string) => {
  const { data } = await api.post('/auth/partner-portal/azure', {
    token,
  });

  return data?.item as Session;
};
