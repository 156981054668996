import React from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Image,
  Text,
  Img,
  Flex,
  Button,
  Center,
  Select,
  Spinner,
} from '@chakra-ui/react';
import icon1 from 'assets/cup.png';
import icon2 from 'assets/trash.png';
import icon3 from 'assets/factory.png';
import icon4 from 'assets/light.png';
import icon5 from 'assets/water.png';
import icon6 from 'assets/gasoline.png';
import world from 'assets/world.png';
import { Filter } from '../Dashboard.props';
import { ImpactReport } from 'types/groups';

interface TableProps {
  setFilter: React.Dispatch<React.SetStateAction<Filter>>;
  filter: string;
  handleDownloadPdf: () => void;
  report: ImpactReport | undefined;
  isLoadingReport?: boolean;
  isDownloading: boolean;
}

const EnvironmentTable = (props: TableProps) => {
  const {
    setFilter,
    filter,
    handleDownloadPdf,
    report,
    isLoadingReport,
    isDownloading,
  } = props;
  return (
    <Box bg="#6F8F7D" borderRadius="md" mb={20} boxShadow={'lg'}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th colSpan={3} color="white">
              <Flex justifyContent={'space-between'}>
                <Flex gridGap={3} alignItems={'center'} flexDirection={'row'}>
                  <Img src={world} />
                  Environmental Impact
                </Flex>
                <Flex gridGap={2}>
                  <Select
                    w="180px"
                    bg="#fff"
                    color={'black'}
                    defaultValue="all"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value as any)}>
                    <option value="all">All Time</option>
                    <option value="1">1 Month</option>
                    <option value="3">3 Month</option>
                    <option value="6">6 Month</option>
                    <option value="ytd">Year to date</option>
                  </Select>
                  <Button
                    isLoading={isDownloading}
                    fontSize={15}
                    onClick={handleDownloadPdf}>
                    Download PDF
                  </Button>
                </Flex>
              </Flex>
            </Th>
          </Tr>
        </Thead>
        <Tbody bgColor={'#FFFFFF'}>
          <Tr>
            <Td border={'1.5px solid #F0F0F0'}>
              <Flex
                flexDirection={'row'}
                align={'center'}
                justifyContent={'space-around'}>
                <Image src={icon1} borderRadius="md" />
                <Flex flexDirection={'column'} gap="15px">
                  <Text
                    fontFamily={'Poppins'}
                    fontSize={16}
                    fontWeight={600}
                    width={137}>
                    Single-Use Cups Eliminated
                  </Text>
                  <Center
                    bgColor={'#E0F5DD'}
                    w={130}
                    h={50}
                    borderRadius={4}
                    color={'#377464'}
                    fontFamily={'Poppins'}
                    fontSize={28}
                    fontWeight={700}
                    alignContent={'center'}
                    textAlign={'center'}>
                    {isLoadingReport ? (
                      <Spinner />
                    ) : (
                      <div>{report?.ret?.toLocaleString() || '0'} </div>
                    )}
                  </Center>
                </Flex>
              </Flex>
            </Td>
            <Td border={'1.5px solid #F0F0F0'}>
              <Flex
                flexDirection={'row'}
                align={'center'}
                justifyContent={'space-around'}>
                <Image src={icon2} borderRadius="md" />
                <Flex flexDirection={'column'} gap="15px">
                  <Text
                    fontFamily={'Poppins'}
                    fontSize={16}
                    fontWeight={600}
                    width={137}>
                    Pounds of Waste Diverted
                  </Text>
                  <Center
                    bgColor={'#E0F5DD'}
                    w={130}
                    h={50}
                    borderRadius={4}
                    color={'#377464'}
                    fontFamily={'Poppins'}
                    fontSize={28}
                    fontWeight={700}
                    justifySelf={'center'}
                    textAlign={'center'}>
                    {isLoadingReport ? (
                      <Spinner />
                    ) : (
                      <div>{report?.wd?.toLocaleString() || '0'} </div>
                    )}
                  </Center>
                </Flex>
              </Flex>
            </Td>
            <Td border={'1.5px solid #F0F0F0'}>
              <Flex
                flexDirection={'row'}
                align={'center'}
                justifyContent={'space-around'}>
                <Image src={icon3} borderRadius="md" />
                <Flex flexDirection={'column'} gap="15px">
                  <Text
                    fontFamily={'Poppins'}
                    fontSize={16}
                    fontWeight={600}
                    width={137}>
                    Pounds of CO2 Eliminated
                  </Text>
                  <Center
                    bgColor={'#E0F5DD'}
                    w={130}
                    h={50}
                    borderRadius={4}
                    color={'#377464'}
                    fontFamily={'Poppins'}
                    fontSize={28}
                    fontWeight={700}
                    justifySelf={'center'}
                    textAlign={'center'}>
                    {isLoadingReport ? (
                      <Spinner />
                    ) : (
                      <div>{report?.co2m?.toLocaleString() || '0'} </div>
                    )}
                  </Center>
                </Flex>
              </Flex>
            </Td>
          </Tr>
          <Tr>
            <Td border={'1.5px solid #F0F0F0'}>
              <Flex
                flexDirection={'row'}
                align={'center'}
                justifyContent={'space-around'}>
                <Image src={icon4} borderRadius="md" />
                <Flex flexDirection={'column'} gap="15px">
                  <Text
                    fontFamily={'Poppins'}
                    fontSize={16}
                    fontWeight={600}
                    width={137}>
                    KWh of Energy Saved
                  </Text>
                  <Center
                    bgColor={'#E0F5DD'}
                    w={130}
                    h={50}
                    borderRadius={4}
                    color={'#377464'}
                    fontFamily={'Poppins'}
                    fontSize={28}
                    fontWeight={700}
                    justifySelf={'center'}
                    textAlign={'center'}>
                    {isLoadingReport ? (
                      <Spinner />
                    ) : (
                      <div>{report?.er?.toLocaleString() || '0'} </div>
                    )}
                  </Center>
                </Flex>
              </Flex>
            </Td>
            <Td border={'1.5px solid #F0F0F0'}>
              <Flex
                flexDirection={'row'}
                align={'center'}
                justifyContent={'space-around'}>
                <Image src={icon5} borderRadius="md" />
                <Flex flexDirection={'column'} gap="15px">
                  <Text
                    fontFamily={'Poppins'}
                    fontSize={16}
                    fontWeight={600}
                    width={137}>
                    Gallons of Water Saved
                  </Text>
                  <Center
                    bgColor={'#E0F5DD'}
                    w={130}
                    h={50}
                    borderRadius={4}
                    color={'#377464'}
                    fontFamily={'Poppins'}
                    fontSize={28}
                    fontWeight={700}
                    justifySelf={'center'}
                    textAlign={'center'}>
                    {isLoadingReport ? (
                      <Spinner />
                    ) : (
                      <div>{report?.wc?.toLocaleString() || '0'} </div>
                    )}
                  </Center>
                </Flex>
              </Flex>
            </Td>
            <Td border={'1.5px solid #F0F0F0'}>
              <Flex
                flexDirection={'row'}
                align={'center'}
                justifyContent={'space-around'}>
                <Image src={icon6} borderRadius="md" />
                <Flex flexDirection={'column'} gap="15px">
                  <Text
                    fontFamily={'Poppins'}
                    fontSize={16}
                    fontWeight={600}
                    width={137}>
                    Liters of Crude Oil Saved
                  </Text>
                  <Center
                    bgColor={'#E0F5DD'}
                    w={130}
                    h={50}
                    borderRadius={4}
                    color={'#377464'}
                    fontFamily={'Poppins'}
                    fontSize={28}
                    fontWeight={700}
                    justifySelf={'center'}
                    textAlign={'center'}>
                    {isLoadingReport ? (
                      <Spinner />
                    ) : (
                      <div>{report?.cos?.toLocaleString() || '0'} </div>
                    )}
                  </Center>
                </Flex>
              </Flex>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

export default EnvironmentTable;
