import React, { FC } from 'react';

import { Flex, Spinner, Text } from '@chakra-ui/react';

import { AzureRedirectViewProps } from './AzureRedirect.props';

const AzureRedirectView: FC<AzureRedirectViewProps> = (props) =>
  props.isLoading ? (
    <Flex flex={1} w="100%" direction="column" align="center" justify="center">
      <Text fontSize="26px">Loading... Please wait</Text>
      <Spinner />
    </Flex>
  ) : null;

export default AzureRedirectView;
