import React from 'react';

import { useAllSkus, usePortal } from 'queries';

import { useMutation, useQueryClient } from 'react-query';
import { createBulkOrder } from 'api/portal';

import { CreateOrderProps } from './CreateOrder.props';

import CreateOrderView from './CreateOrder.view';

const CreateOrderContainer = (props: CreateOrderProps) => {
  const _selected = props.location.state?.selected || [];

  const queryClient = useQueryClient();

  const { data, isLoading } = usePortal();
  const sites = [];
  if (data?.sites.length) {
    sites.push(...data.sites);
  }
  if (data?.groups.length) {
    const groupSites = data.groups[0].sites || [];
    if (groupSites.length) {
      sites.push(...groupSites);
    }
  }

  const { allSkus, isLoading: isLoadingSkus } = useAllSkus(sites || []);

  const { mutate, isLoading: isCreating } = useMutation(createBulkOrder, {
    onSuccess: (resp) => {
      props.history.push('/order/details', {
        items: resp?.item,
      } as any);

      queryClient.invalidateQueries({
        queryKey: ['fetch-order-history'],
      });
    },
  });

  const onCreateOrder = (payload: any) => {
    mutate(payload);
  };

  return (
    <CreateOrderView
      _selected={_selected}
      portal={data}
      allSkus={allSkus}
      isCreating={isCreating}
      onCreateOrder={onCreateOrder}
      isLoading={isLoading || isLoadingSkus}
    />
  );
};

export default CreateOrderContainer;
