import React, { FC } from 'react';

import {
  Flex,
  Text,
  Button,
  Center,
  Spinner,
  SimpleGrid,
} from '@chakra-ui/react';

import { SiDropbox } from 'react-icons/si';
import { MdCheckCircle } from 'react-icons/md';

import { OrderDetailsViewProps } from './OrderDetails.props';

const OrderDetailsView: FC<OrderDetailsViewProps> = ({
  items,
  allSkus,
  goBack,
  isLoading,
}) => {
  return (
    <Flex flex={1} direction="column">
      {isLoading ? (
        <Center flex={1}>
          <Spinner size="lg" />
        </Center>
      ) : items?.length > 0 ? (
        <Flex gap="0px" direction="column">
          <Center mt="100px" mb="8">
            <MdCheckCircle color="#53D367" size="100px" />
          </Center>
          <Text
            maxW="90%"
            alignSelf="center"
            fontSize="24px"
            textAlign="center"
            fontWeight="bold">
            Order Requested
          </Text>
          <Text
            maxW="400px"
            alignSelf="center"
            fontSize="16px"
            textAlign="center"
            mt="4">
            Thanks for your order! The r.Turn team is preparing as requested.
            Call or email with any questions.
          </Text>
          {items?.map((order) => {
            return (
              <Flex px="20px" direction="column" flex={1} key={order._id}>
                <Text
                  mt="4"
                  py="2"
                  w="100%"
                  maxW="1000px"
                  alignSelf="center"
                  textAlign="center"
                  borderRadius="10px 10px 0px 0px"
                  border="1px solid #2FB64A"
                  fontSize="20px"
                  fontWeight="bold">
                  Order summary - {(order as any)?.site}
                </Text>
                <SimpleGrid
                  mb="10"
                  border="1px solid #2FB64A"
                  borderTop="none"
                  borderRadius="0px 0px 10px 10px"
                  alignSelf="center"
                  w="100%"
                  maxW="1000px"
                  templateColumns={['1fr', '1fr', '2fr 1fr']}
                  columns={[1, 1, 2]}>
                  <Flex
                    flex={1}
                    direction="column"
                    borderRight={{ base: 'none', md: '1px solid #2FB64A' }}
                    borderBottom={{ base: '1px solid #2FB64A', md: 'none' }}>
                    {order?.details?.map((d, i) => {
                      const sku = allSkus
                        ?.find((s) => s.site === (order as any).site)
                        ?.skus?.find((s) => s._id === d.sku);

                      return (
                        <Flex
                          borderBottom={
                            order?.details?.length === i + 1
                              ? ''
                              : '1px solid #2FB64A'
                          }
                          px="30px"
                          py="10px"
                          key={d._id}
                          align="center"
                          gap="20px">
                          <SiDropbox size="22px" color="#2FB64A" />
                          <Text fontWeight="bold" w="100%">
                            {sku?.name || ''}
                          </Text>
                          <Text fontWeight="bold">{d.casesCount || '0'}</Text>
                        </Flex>
                      );
                    })}
                  </Flex>
                  <Flex px="30px" py="10px" flexDirection={'column'}>
                    {Boolean(order?.notes) && (
                      <Text fontWeight={'bold'} fontSize={'18px'}>
                        Order Notes:{' '}
                      </Text>
                    )}
                    <Text fontWeight="bold">{order?.notes || '-'}</Text>
                  </Flex>
                </SimpleGrid>
              </Flex>
            );
          })}

          <Button
            size="lg"
            mb="100px"
            onClick={() => goBack()}
            variant="outline"
            alignSelf="center">
            Create new order
          </Button>
        </Flex>
      ) : (
        <Center flex={1} flexDirection="column" justifyContent="center">
          <Text>Order Not Found</Text>
          <Button onClick={() => goBack()}>GO BACK</Button>
        </Center>
      )}
    </Flex>
  );
};

export default OrderDetailsView;
