import React, { useState } from 'react';

import { useQuery } from 'react-query';
import { getImpactReport } from 'api/portal';
import { useAllSkus, usePortal } from 'queries';

import { Site } from 'types/site';
import { Filter, DashboardProps, MonthAndYear } from './Dashboard.props';

import DashboardView from './Dashboard.view';

const monthsMap: Record<number, string> = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

function createDateFilters(option: Filter) {
  const now = new Date();
  const currentMonth = now.getMonth() + 1; // Months are 0-based in JavaScript
  const currentYear = now.getFullYear();
  let numMonths: number;

  switch (option) {
    case 'all':
      return [];
    case '1':
      numMonths = 1;
      break;
    case '3':
      numMonths = 3;
      break;
    case '6':
      numMonths = 6;
      break;
    case 'ytd':
      numMonths = currentMonth;
      break;
    default:
      return [];
  }

  const filters: any[] = [];

  for (let i = 0; i < numMonths; i++) {
    let month = ((currentMonth - i - 1 + 12) % 12) + 1;
    let year = currentYear + Math.floor((currentMonth - i - 1) / 12);

    filters.push({ month, year });
  }

  // Reverse the filters to be in ascending order
  return filters.reverse();
}

function getMonthAndYear(
  option: Filter,
  monthAndYearData: Array<MonthAndYear>,
) {
  if (monthAndYearData.length === 0) {
    return null;
  }

  switch (option) {
    case 'all':
      return null;

    case '1':
      return `${monthsMap[monthAndYearData[0].month]} ${
        monthAndYearData[0].year
      }`;

    case '3':
      return `${monthsMap[monthAndYearData[0].month]} - ${
        monthsMap[monthAndYearData[monthAndYearData.length - 1].month]
      } ${monthAndYearData[0].year}`;

    case '6':
      return `${monthsMap[monthAndYearData[0].month]} - ${
        monthsMap[monthAndYearData[monthAndYearData.length - 1].month]
      } ${monthAndYearData[0].year}`;

    case 'ytd':
      return `${monthsMap[monthAndYearData[0].month]} - ${
        monthsMap[monthAndYearData[monthAndYearData.length - 1].month]
      } ${monthAndYearData[0].year}`;

    default:
      return null;
  }
}

const DashboardContainer = (props: DashboardProps) => {
  const { data, isLoading } = usePortal();

  const _selected = props.location.state?.selected || [];

  const [filter, setFilter] = useState<Filter>('all');
  const [selected, setSelected] = useState<Site[]>(_selected);

  const sites = [];
  if (data?.sites.length) {
    sites.push(...data.sites);
  }
  if (data?.groups.length) {
    const groupSites = data.groups[0].sites || [];
    if (groupSites.length) {
      sites.push(...groupSites);
    }
  }

  const { allSkus, isLoading: isLoadingSkus } = useAllSkus(sites);

  const { data: reportData, isLoading: isLoadingReport } = useQuery(
    ['fetch-impact-report', selected?.map((s) => s._id), filter],
    async () => {
      const site = selected.map((s) => s._id);
      const dateFilters = createDateFilters(filter);

      const response = await getImpactReport({
        site,
        dateFilters,
      });

      const report = {
        wd: 0,
        co2m: 0,
        wc: 0,
        er: 0,
        cos: 0,
        ret: 0,
      };

      const monthAndYearData: Array<MonthAndYear> = [];

      for (const impact of response) {
        report.wd += impact.wd;
        report.co2m += impact.co2m;
        report.wc += impact.wc;
        report.er += impact.er;
        report.cos += impact.cos;
        report.ret += impact.ret;

        monthAndYearData.push({
          month: impact?.month || 0,
          year: impact?.year || 0,
        });
      }

      monthAndYearData.sort((a, b) => {
        if (a.year === b.year) {
          return a.month - b.month;
        }

        return a.year - b.year;
      });

      const monthAndYear = getMonthAndYear(filter, monthAndYearData);

      return { report, monthAndYear };
    },
  );

  return (
    <DashboardView
      portal={data}
      filter={filter}
      allSkus={allSkus}
      selected={selected}
      setFilter={setFilter}
      setSelected={setSelected}
      report={reportData?.report}
      monthAndYear={reportData?.monthAndYear}
      isLoadingReport={isLoadingReport}
      isLoading={isLoadingSkus || isLoading}
    />
  );
};

export default DashboardContainer;
