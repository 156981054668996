import React, { FC } from 'react';

import { Text, Flex, Center, Box } from '@chakra-ui/react';

import icon1 from 'assets/cup-1.png';
import icon2 from 'assets/trash-1.png';
import icon3 from 'assets/factory-1.png';
import icon4 from 'assets/light-1.png';
import icon5 from 'assets/water-1.png';
import icon6 from 'assets/gasoline-1.png';

import {
  ItemLabel,
  SiteLogo,
  ItemIcon,
  RturnLogo,
  ItemValue,
  HeaderRow,
  FooterText,
  PdfContainer,
  ItemContainer,
  ValuContainer,
  ContentContainer,
  ItemName,
  ItemDate,
} from './Dashboard.style';

import { PdfContentProps } from './Dashboard.props';

const PdfContent: FC<PdfContentProps> = ({
  logo,
  report,
  monthAndYear,
  siteName,
}) => (
  <PdfContainer id="impact-report-grid">
    <HeaderRow>
      <RturnLogo />
      <Text maxW="100px" lineHeight="1.2" mb="10px">
        Environmental Impact Report
      </Text>
    </HeaderRow>
    <ContentContainer>
      <Flex flexDir="row-reverse" alignItems="flex-start" gridGap="15px">
        <ItemDate>{Boolean(monthAndYear) ? monthAndYear : ''}</ItemDate>
        {logo ? (
          <Flex
            flexDir="column"
            justifyContent="center"
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
            }}>
            <SiteLogo id="site-logo" src={logo} />
            <ItemName>{siteName}</ItemName>
          </Flex>
        ) : (
          <Flex
            h="50px"
            mt="10px"
            flexDir="column"
            justifyContent="center"
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
            }}>
            <ItemName>{siteName}</ItemName>
          </Flex>
        )}
      </Flex>
      <Flex direction="column" flex={1} justify="space-around" mt={12}>
        <Flex justify="space-around" gap="20px">
          <ItemContainer>
            <Center>
              <ItemIcon src={icon1} />
            </Center>
            <Box>
              <ItemLabel>Single-Use Cups Eliminated</ItemLabel>
              <ValuContainer>
                <ItemValue>{report?.ret?.toLocaleString() || '0'}</ItemValue>
              </ValuContainer>
            </Box>
          </ItemContainer>
          <ItemContainer>
            <Center>
              <ItemIcon src={icon2} />
            </Center>
            <Box>
              <ItemLabel>Pounds of Waste Diverted</ItemLabel>
              <ValuContainer>
                <ItemValue>{report?.wd?.toLocaleString() || '0'}</ItemValue>
              </ValuContainer>
            </Box>
          </ItemContainer>
        </Flex>

        <Flex justify="space-around" gap="20px">
          <ItemContainer>
            <Center>
              <ItemIcon src={icon3} />
            </Center>
            <Box>
              <ItemLabel>Pounds of CO2 Eliminated</ItemLabel>
              <ValuContainer>
                <ItemValue>{report?.co2m?.toLocaleString() || '0'}</ItemValue>
              </ValuContainer>
            </Box>
          </ItemContainer>
          <ItemContainer>
            <Center>
              <ItemIcon src={icon4} />
            </Center>
            <Box>
              <ItemLabel maxW="100px">KWh of Energy Saved</ItemLabel>
              <ValuContainer>
                <ItemValue>{report?.er?.toLocaleString() || '0'}</ItemValue>
              </ValuContainer>
            </Box>
          </ItemContainer>
        </Flex>

        <Flex justify="space-around" gap="20px">
          <ItemContainer>
            <Center>
              <ItemIcon src={icon5} />
            </Center>
            <Box>
              <ItemLabel>Gallons of Water Saved</ItemLabel>
              <ValuContainer>
                <ItemValue>{report?.wc?.toLocaleString() || '0'}</ItemValue>
              </ValuContainer>
            </Box>
          </ItemContainer>
          <ItemContainer>
            <Center>
              <ItemIcon src={icon6} />
            </Center>
            <Box>
              <ItemLabel>Liters of Crude Oil Saved</ItemLabel>
              <ValuContainer>
                <ItemValue>{report?.cos?.toLocaleString() || '0'}</ItemValue>
              </ValuContainer>
            </Box>
          </ItemContainer>
        </Flex>
      </Flex>
    </ContentContainer>
    <FooterText>A better future is in your hands</FooterText>
  </PdfContainer>
);

export default PdfContent;
