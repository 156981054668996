import React, { FC } from 'react';

import { Redirect, Route } from 'react-router-dom';

import { ProtectedRouteProps } from './ProtectedRoute.props';

const ProtectedRouteView: FC<ProtectedRouteProps> = ({
  isAuth,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRouteView;
