import React, { useEffect, useState } from 'react';

import {
  Modal,
  Button,
  Checkbox,
  ModalBody,
  IconButton,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { useTheme } from '@chakra-ui/system';
import { CircularProgress } from '@chakra-ui/progress';
import { Center, Flex, Heading, Stack, Text } from '@chakra-ui/layout';

import { MdError } from 'react-icons/md';
import { GrNext, GrPrevious } from 'react-icons/gr';

import { CustomTableProps } from './CustomTable.props';

const LIMITS_PER_PAGE = 10;

const css = {
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
  '&::-webkit-scrollbar-track': {
    width: '10px',
    height: '10px',
    background: '#10722450',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#1E9435',
    borderRadius: '24px',
  },
};

const CustomTableView = <T extends object>(props: CustomTableProps<T>) => {
  const theme = useTheme();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [checked, setChecked] = useState<string[]>([]);

  const {
    data,
    mt = 2,
    type,
    onDelete,
    isLoading,
    isDeleting,
    maxH = '52vh',
    minW = '1200px',
    pagination,
    isSelectable = true,
    buttonContainerProps = {},
    children: { renderRow, renderEmpty = () => null, headerRow },
  } = props;

  const hasChanged = data.toString();

  useEffect(() => {
    setChecked([]);
  }, [hasChanged]);

  const pc = theme?.colors?.primary['800'];

  return (
    <Flex direction="column" pos="relative" mt={mt}>
      {checked?.length > 0 && onDelete && (
        <Flex pos="absolute" right={0} top="-122px" {...buttonContainerProps}>
          <Button
            mr={2}
            w="150px"
            variant="outline"
            isDisabled={isDeleting}
            onClick={() => setChecked([])}>
            Cancel
          </Button>
          <Button
            w="150px"
            bg="red.500"
            isLoading={isDeleting}
            onClick={onOpen}
            _hover={{ bg: 'red.600' }}
            _active={{ bg: 'red.900' }}>
            Delete
          </Button>
          <Modal
            size="2xl"
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent borderRadius="30px">
              <ModalCloseButton _focus={{ outline: 'none' }} />
              <ModalBody textAlign="center">
                <Center>
                  <MdError size="100px" fill="red" />
                </Center>
                <Heading
                  color="support.500"
                  textAlign="center"
                  fontSize="30px"
                  whiteSpace="pre-line">
                  Are you sure you want to{'\n'}delete these {type || ''}?
                </Heading>
                <Text my={4} fontSize="20px">
                  This action can't be undone
                </Text>
                <Center mb={5}>
                  <Button
                    fontWeight="medium"
                    mr={4}
                    w="150px"
                    variant="outline"
                    onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    fontWeight="medium"
                    onClick={() => {
                      onDelete(checked, () => setChecked([]));

                      onClose();
                    }}>
                    Yes, Delete {type || ''}
                  </Button>
                </Center>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Flex>
      )}
      <Flex w="100%" overflow="auto" css={css}>
        <Flex minW={minW} w="100%" direction="column" fontSize="13px">
          <Flex
            px={6}
            py={4}
            flex={1}
            bg={`${pc}80`}
            color="support.500"
            userSelect="none"
            fontWeight="bold"
            borderRadius="5px">
            {isSelectable && (
              <Checkbox
                mr={6}
                colorScheme="primary"
                borderColor="primary.500"
                isChecked={
                  data?.length > 0
                    ? data
                        ?.map((j: any) => j?._id)
                        ?.every((i) => checked.includes(i))
                    : false
                }
                onChange={(e) => {
                  if (!data) {
                    return;
                  }

                  if (data.length === 0) {
                    return;
                  }

                  if (e.target.checked) {
                    setChecked(data.map((i: any) => i._id as string));
                  } else {
                    setChecked([]);
                  }
                }}
              />
            )}
            {headerRow}
          </Flex>
          <Stack
            mt={2}
            pb={4}
            maxH={maxH}
            //@ts-ignore
            overflowY="overlay"
            css={css}>
            {isLoading ? (
              <Center py={20} px={5}>
                <CircularProgress isIndeterminate color="primary.400" />
              </Center>
            ) : data.length > 0 ? (
              data.map((item: any, index) => {
                return (
                  <Flex
                    px={6}
                    py={4}
                    flex={1}
                    cursor="pointer"
                    color="support.500"
                    borderRadius="5px"
                    bg={(index + 1) % 2 === 0 ? `${pc}30` : undefined}
                    _hover={{
                      bg: 'primary.800',
                      transition: '0.3s',
                    }}
                    key={`${JSON.stringify(item)}-${index}`}>
                    {isSelectable && (
                      <Checkbox
                        mr={6}
                        colorScheme="primary"
                        borderColor="primary.500"
                        isChecked={checked.includes(item?._id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setChecked((prev) => [...prev, item?._id]);
                          } else {
                            setChecked((prev) => [
                              ...prev.filter((i) => i !== item?._id),
                            ]);
                          }
                        }}
                      />
                    )}
                    {renderRow(item)}
                  </Flex>
                );
              })
            ) : (
              renderEmpty?.()
            )}
          </Stack>
        </Flex>
      </Flex>
      {pagination && pagination.totalCount > LIMITS_PER_PAGE && (
        <Flex mt={10} align="center" justifyContent="flex-end">
          <IconButton
            w="20px"
            aria-label="next-page"
            icon={<GrPrevious />}
            isDisabled={pagination.page === 1}
            onClick={() => {
              pagination.setPage((old: number) => (data ? old - 1 : old));
            }}
          />
          <Text mx={6}>
            {pagination.page} out of {Math.ceil(pagination.totalPages)}
          </Text>
          <IconButton
            w="20px"
            aria-label="next-page"
            icon={<GrNext />}
            isDisabled={
              data.length > 0 ? pagination.totalPages <= pagination.page : true
            }
            onClick={() =>
              pagination.setPage((old: number) => (data ? old + 1 : old))
            }
          />
        </Flex>
      )}
    </Flex>
  );
};

export default CustomTableView;
