import React from 'react';

import {
  FormControl,
  FormLabel,
  Text,
  FormHelperText,
  Textarea,
} from '@chakra-ui/react';
import { useField } from 'formik';

import { FormikTextAreaProps } from './FormikTextArea.props';

const FormikTextAreaView = ({
  name,
  label,
  helperText,
  error,
  labelProps = {},
  ...props
}: FormikTextAreaProps): JSX.Element => {
  const [, meta, field] = useField(name);
  return (
    <FormControl mt={2}>
      {label && (
        <FormLabel
          fontSize="12px"
          fontWeight="600"
          htmlFor={name}
          color="#afafaf"
          textTransform="capitalize"
          {...labelProps}>
          {label}
        </FormLabel>
      )}
      <Textarea
        {...props}
        type={name}
        id={name}
        isInvalid={Boolean(error || meta.error)}
        aria-describedby={`${name}-helper-text`}
        onChange={(e) => field.setValue(e.target.value)}
        bg="inputColor"
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {(error || meta.error) && (
        <Text color="red.500" fontSize="sm">
          {error || meta.error}
        </Text>
      )}
    </FormControl>
  );
};

export default FormikTextAreaView;
