import React, { useState } from 'react';
import { Box, Flex, Button } from '@chakra-ui/react';
import { SlideTabsProps } from './SlideTabs.props';

const SlideTabs = (props: SlideTabsProps) => {
  const { setSlidePosition, slidePosition } = props;

  const handleButtonClick = (position: React.SetStateAction<number>) => {
    setSlidePosition(position);
  };

  return (
    <Box py={8}>
      <Flex bg="#EFEFEF" borderRadius="full" w="400px" mx="auto">
        <Button
          p={3}
          width={'50%'}
          borderRadius="full"
          colorScheme={slidePosition === 1 ? 'primary' : 'gray'}
          onClick={() => handleButtonClick(1)}
          fontFamily={'Poppins'}
          fontSize={15}
          lineHeight={16}
          fontWeight="600"
          textColor={slidePosition === 1 ? 'white' : '#949494'}>
          Environmental Impact
        </Button>
        <Button
          p={3}
          borderRadius="full"
          width={'50%'}
          colorScheme={slidePosition === 0 ? 'primary' : 'gray'}
          onClick={() => handleButtonClick(0)}
          fontFamily={'Poppins'}
          fontSize={15}
          lineHeight={16}
          fontWeight="600"
          textColor={slidePosition === 0 ? 'white' : '#949494'}>
          My orders
        </Button>
      </Flex>
    </Box>
  );
};

export default SlideTabs;
