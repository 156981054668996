import React, { FC } from 'react';

import {
  Box,
  Flex,
  Text,
  Center,
  Avatar,
  Button,
  useDisclosure,
} from '@chakra-ui/react';

import { MdArrowDropDown, MdArrowDropUp, MdLogout } from 'react-icons/md';

import { $session } from 'stores';

import { useQueryClient } from 'react-query';

import { useHistory } from 'react-router-dom';

import { googleLogout } from '@react-oauth/google';

import { AnimatePresence, motion } from 'framer-motion';

import { UserPillViewProps } from './UserPill.props';

const MotionBox = motion(Box);

const _contprops = {
  mr: 1,
  w: '100%',
  minW: '200px',
  maxW: '200px',
  zIndex: 2,
  position: 'relative' as any,
};

const _defaultprops = {
  px: 2,
  py: 2,
  w: '100%',
  zIndex: 1,
  bg: '#000',
  maxW: '200px',
  color: '#fff',
  align: 'center',
  borderRadius: '20px',
  alignSelf: 'flex-end',
};

const _textprops = {
  mx: 2,
  flex: 1,
  noOfLines: 1,
  fontSize: '13px',
  fontWeight: 'bold',
};

const _dropdnprops = {
  left: 0,
  right: 0,
  bg: '#fff',
  h: '80px',
  pt: '30px',
  px: '10px',
  pb: '10px',
  top: '50%',
  shadow: 'md',
  borderBottomRadius: '20px',
  position: 'absolute' as any,
  initial: { opacity: 0, y: -80 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -80 },
  transition: { duration: 0.3 },
};

const UserPillView: FC<UserPillViewProps> = (props) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const username = $session.selectors.useUsername();

  const { isOpen, onToggle } = useDisclosure();

  const onLogout = () => {
    googleLogout();

    queryClient.removeQueries();

    $session.actions.clearSession();

    history.push('/login');
  };

  return (
    <Flex {..._contprops}>
      <Flex {..._defaultprops} {...props} onClick={onToggle}>
        <Avatar size="xs" />
        <Text {..._textprops}>{username}</Text>
        <Box
          size="20px"
          cursor="pointer"
          as={isOpen ? MdArrowDropUp : MdArrowDropDown}
        />
      </Flex>
      <AnimatePresence exitBeforeEnter>
        {isOpen && (
          <MotionBox {..._dropdnprops}>
            <Center>
              <Button
                size="sm"
                variant="ghost"
                onClick={() => {
                  onToggle();

                  onLogout();
                }}
                leftIcon={<MdLogout size="18px" />}>
                Log out
              </Button>
            </Center>
          </MotionBox>
        )}
      </AnimatePresence>
    </Flex>
  );
};

export default UserPillView;
