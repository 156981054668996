import React from 'react';

import {
  FormControl,
  FormLabel,
  Text,
  FormHelperText,
  Input,
} from '@chakra-ui/react';
import { useField } from 'formik';

import { FormikInputProps } from './FormikInput.props';

const FormikInputView = ({
  name,
  label,
  helperText,
  labelProps,
  error,
  type,
  defaultValue,
  containerProps,
  isReadOnly,
  ...props
}: FormikInputProps): JSX.Element => {
  const [field, meta, helpers] = useField(name);
  return (
    <FormControl mt={3} {...containerProps}>
      {label && (
        <FormLabel htmlFor={name} {...labelProps} textTransform="capitalize">
          {label}
        </FormLabel>
      )}
      <Input
        type={type || name}
        id={name}
        isInvalid={meta.touched && meta.error ? true : false}
        aria-describedby={`${name}-helper-text`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          helpers.setValue(e.target.value)
        }
        value={field.value || defaultValue}
        bg={isReadOnly ? '#DEDCDC' : 'inputColor'}
        onBlur={() => helpers.setTouched(true)}
        isReadOnly={isReadOnly}
        {...props}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {meta.error && meta.touched && (
        <Text color="red.500" fontSize="sm">
          {meta.error}
        </Text>
      )}
    </FormControl>
  );
};

export default FormikInputView;
