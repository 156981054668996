import React, { useEffect } from 'react';

import { useMsal } from '@azure/msal-react';

import { $session } from 'stores';

import { azureLogin } from 'api/auth';
import { useMutation } from 'react-query';

import { InteractionStatus } from '@azure/msal-browser';
import { AzureRedirectProps } from './AzureRedirect.props';

import AzureRedirectView from './AzureRedirect.view';

const AzureRedirectContainer = (props: AzureRedirectProps) => {
  const { instance, inProgress } = useMsal();

  const { mutate, isLoading } = useMutation(azureLogin, {
    onSuccess: (session) => {
      $session.actions.setSession(session);

      props.history.replace('/');
    },
  });

  useEffect(() => {
    const callbackId = instance.addEventCallback((event: any) => {
      if (event?.payload?.accessToken) {
        mutate(event?.payload?.accessToken);
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  return (
    <AzureRedirectView
      isLoading={inProgress !== InteractionStatus.None || isLoading}
    />
  );
};

export default AzureRedirectContainer;
