import { Box, Checkbox, Flex, Image, Text } from '@chakra-ui/react';

import bg from 'assets/cms-bg-preview.png';

import { chakraStyled } from 'utils';

export const Container = chakraStyled(Flex, {
  flex: 1,
  flexDirection: 'column',
});

export const HeaderContainer = chakraStyled(Flex, {
  position: 'relative',
  direction: 'column',
});

export const Banner = chakraStyled(Image, {
  h: '150px',
  w: '100%',
  objectFit: 'cover',
  zIndex: 1,
  src: bg,
  backgroundPosition: 'center',
});

export const FormContainer = chakraStyled(Flex, {
  m: '10px',
  minW: '800px',
  maxW: '800px',
  direction: 'column',
  borderRadius: '10px',
  border: '1px solid #2FB64A',
  bg: '#9FC2AE30',
});

export const FormHeader = chakraStyled(Flex, {
  flex: 1,
  p: '10px 20px',
  justify: 'space-between',
});

export const FormHeading = chakraStyled(Text, {
  fontSize: '18px',
  fontWeight: '600',
});

export const Check = chakraStyled(Checkbox, {
  colorScheme: 'primary',
  sx: {
    '.chakra-checkbox__control[data-focus]': {
      boxShadow: 'none',
    },
    '.chakra-checkbox__control': {
      bg: '#fff',
    },
    '.chakra-checkbox__control[data-checked]': {
      bg: '#2FB64A',
    },
  },
});

export const FormContent = chakraStyled(Flex, {
  flex: 1,
  direction: 'column',
  p: '10px 20px',
  gap: '15px',
  borderTop: '1px solid #2FB64A',
});
