enum ROUTES {
  // AUTH ROUTE ENTRY
  AUTH_LOGIN = 'AUTH_LOGIN',
  AUTH_AZURE_REDIRECT = 'AUTH_AZURE_REDIRECT',

  // PORTAL ROUTE ENTRY
  PORTAL_LANDING = 'PORTAL_LANDING',
  PORTAL_CREATE_ORDER = 'PORTAL_CREATE_ORDER',
  PORTAL_NOT_FOUND = 'PORTAL_NOT_FOUND',
  PORTAL_ORDER_DETAILS = 'PORTAL_ORDER_DETAILS',
  PORTAL_DASHBOARD = 'PORTAL_DASHBOARD',
  PORTAL_ORDER_HISTORY = 'PORTAL_ORDER_HISTORY',
}

export const routesSettings = Object.freeze({
  // AUTH ROUTE SETTINGS ENTRY

  [ROUTES.AUTH_LOGIN]: {
    name: 'Login',
    path: '/login',
    getPath: () => `/login`,
  },
  [ROUTES.AUTH_AZURE_REDIRECT]: {
    name: 'Azure Redirect',
    path: '/azure-redirect',
    getPath: () => '/azure-redirect',
  },

  // PORTAL ROUTE SETTINGS ENTRY
  [ROUTES.PORTAL_LANDING]: {
    name: 'Landing',
    path: '/:portalName',
    getPath: () => `/:portalName`,
  },
  [ROUTES.PORTAL_CREATE_ORDER]: {
    name: 'Create Order',
    path: '/:portalName/create',
    getPath: () => `/:portalName/create`,
  },
  [ROUTES.PORTAL_ORDER_DETAILS]: {
    name: 'Order Details',
    path: '/order/details',
    getPath: () => `/order/details`,
  },
  [ROUTES.PORTAL_CREATE_ORDER]: {
    name: 'CreateOrder',
    path: '/create',
  },
  [ROUTES.PORTAL_DASHBOARD]: {
    name: 'Dashboard',
    path: '/',
  },
  [ROUTES.PORTAL_ORDER_HISTORY]: {
    name: 'OrderHistory',
    path: '/history',
  },
  [ROUTES.PORTAL_NOT_FOUND]: {
    name: 'Not Found',
    path: '/*',
  },
});

export default ROUTES;
