import React, { FC, useEffect } from 'react';

import { Flex } from '@chakra-ui/react';

import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { $session } from 'stores';

// Routes
import Login from './Login';
import NotFound from './NotFound';
import Dashboard from './Dashboard';
import CreateOrder from './CreateOrder';
import OrderDetails from './OrderDetails';
import AzureRedirect from './AzureRedirect';

import ROUTES, { routesSettings } from 'constants/routes';

import NavBar from 'components/core/NavBar';
import ProtectedRoute from 'components/core/ProtectedRoute';

const RootRoutes: FC = () => {
  const isAuth = $session.selectors.useIsAuth();

  const history = useHistory();
  const location = useLocation();

  const isNotDashboard = location.pathname !== '/';
  const isNotCreateOrder = location.pathname !== '/create';
  const isNotOrderHistory = location.pathname !== '/history';

  useEffect(() => {
    const isDefaultPath = location.pathname === '/';

    if (isDefaultPath) {
      const _isAuth = $session.actions.getIsAuth();

      if (!_isAuth) {
        history.replace('/login');
      }
    }
  }, []);

  return (
    <Flex flex={1} direction="column">
      {isAuth && isNotCreateOrder && isNotDashboard && isNotOrderHistory && (
        <NavBar />
      )}
      <Flex w="100%" flexGrow={1} flexBasis={0} marginX="auto" overflow="auto">
        <Switch>
          <Route
            exact
            key={ROUTES.AUTH_AZURE_REDIRECT}
            path={routesSettings.AUTH_AZURE_REDIRECT.path}
            component={AzureRedirect}
          />
          <Route
            exact
            component={Login}
            key={ROUTES.AUTH_LOGIN}
            path={routesSettings.AUTH_LOGIN.path}
          />

          <ProtectedRoute
            exact
            isAuth={isAuth}
            component={OrderDetails}
            key={ROUTES.PORTAL_ORDER_DETAILS}
            path={routesSettings.PORTAL_ORDER_DETAILS.path}
          />
          <ProtectedRoute
            exact
            isAuth={isAuth}
            component={CreateOrder}
            key={ROUTES.PORTAL_CREATE_ORDER}
            path={routesSettings.PORTAL_CREATE_ORDER.path}
          />
          <ProtectedRoute
            exact
            isAuth={isAuth}
            component={Dashboard}
            key={ROUTES.PORTAL_DASHBOARD}
            path={routesSettings.PORTAL_DASHBOARD.path}
          />

          {/* this route should be at the last */}
          <ProtectedRoute
            exact
            isAuth={isAuth}
            component={NotFound}
            key={ROUTES.PORTAL_NOT_FOUND}
            path={routesSettings.PORTAL_NOT_FOUND.path}
          />
        </Switch>
      </Flex>
    </Flex>
  );
};

export default RootRoutes;
