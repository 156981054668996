import { extendTheme } from '@chakra-ui/react';

import '@fontsource/poppins';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

export const theme = extendTheme({
  fonts: {
    heading: 'Poppins',
    body: 'Poppins',
    mono: 'Poppins',
  },
  colors: {
    primary: {
      100: '#075015',
      200: '#075015',
      300: '#107224',
      400: '#1E9435',
      500: '#2FB64A',
      600: '#44D862',
      700: '#5DFA7D',
      800: '#83FF9C',
      900: '#83FF9C50',
    },
    support: {
      100: '#304338',
      200: '#445C4E',
      300: '#597665',
      400: '#6F8F7D',
      500: '#304338',
      600: '#87A895',
      700: '#9FC2AE',
      800: '#B8DCC7',
    },
    warning: {
      100: '#4F3A03',
      200: '#71540A',
      300: '#936F14',
      400: '#B58B22',
      500: '#F9C746',
      600: '#FFD771',
      700: '#FFE39A',
      800: '#FFEEC3',
    },
  },
  fontSizes: {
    xs: '12px',
    sm: '13px',
    md: '14px',
    lg: '18px',
    xl: '24px',
    '2xl': '26px',
    '3xl': '28px',
  },
  lineHeights: {
    normal: 'normal',
    none: '1',
    shorter: '1.25',
    short: '1.375',
    base: '1.5',
    tall: '1.625',
    taller: '2',
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  components: {
    Button: {
      baseStyle: {
        width: '168px',
        borderRadius: '8px',
        _focus: {
          boxShadow: 'none',
          outline: 'none',
        },
      },
      sizes: {
        md: {
          width: '168px',
          height: '2.5rem',
          fontSize: '16px',
        },
        lg: {
          fontSize: '16px',
          height: '2.5rem',
          width: '300px',
        },
      },
      variants: {
        outline: {
          border: '2px solid',
        },
      },
      defaultProps: {
        variant: 'solid',
        colorScheme: 'primary',
      },
    },
  },
});
