import React from 'react';

import { BrowserRouter } from 'react-router-dom';

import { theme, queryClient, msalInstance } from 'utils';

import { ChakraProvider } from '@chakra-ui/react';

import { MsalProvider } from '@azure/msal-react';

import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import Routes from 'routes';

const App: React.FC = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme} resetCSS>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </ChakraProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </MsalProvider>
  );
};

export default App;
