import React, { FC } from 'react';

import {
  Text,
  Flex,
  Center,
  Spinner,
  Accordion,
  SimpleGrid,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
} from '@chakra-ui/react';
import { SiDropbox } from 'react-icons/si';

import { formatedDate } from 'utils';

import { CustomTable } from 'components/modules/CustomTable';

import { Status } from 'types/items';
import { OrderHistoryViewProps } from './OrderHistory.props';

import { Container } from './OrderHistory.style';

const OrderHistoryView: FC<OrderHistoryViewProps> = ({
  orders,
  allSkus,
  isLoading,
  pagination,
  isLoadingPortal,
}) => {
  return (
    <Flex flex={1}>
      {isLoadingPortal ? (
        <Center flex={1}>
          <Spinner size="xl" />
        </Center>
      ) : (
        <>
          <Container>
            <Container ml="10px" maxW="80vw" overflowX="auto">
              <CustomTable
                maxH="40vh"
                isSelectable={false}
                minW="1200px"
                data={orders}
                pagination={pagination}
                isLoading={isLoading}>
                {{
                  headerRow: (
                    <>
                      <Text flex={1}>Order No</Text>
                      <Text flex={1.5}>Created</Text>
                      <Text flex={1.4}>Schedule</Text>
                      <Text flex={1.5}>Site</Text>
                      <Text flex={1.3} textAlign="center">
                        Status
                      </Text>
                      <Text flex={1} textAlign="center">
                        No of Cases
                      </Text>
                    </>
                  ),
                  renderRow: (order) => {
                    const totalUnits = order?.details?.reduce(
                      (acc, curr) => acc + curr.casesCount,
                      0,
                    );
                    return (
                      <Accordion defaultIndex={[1]} allowMultiple flex={1}>
                        <AccordionItem border="none" backgroundColor="inherit">
                          <AccordionButton
                            p={0}
                            m={0}
                            fontSize="md"
                            _focus={{ boxShadow: 'none' }}
                            _active={{ outline: 'none' }}
                            _hover={{
                              bg: 'inherit',
                            }}>
                            <Text flex={0.4}>{order?.orderNumber || '-'}</Text>
                            <Text flex={1}>
                              {formatedDate(order?.createdAt, true)}
                            </Text>
                            <Text flex={1.5}>
                              {formatedDate(order?.deliveryAt, true)}
                            </Text>
                            <Text flex={1.3} textAlign="left">
                              {order?.site?.name || '-'}
                            </Text>
                            <Text flex={1.2} textAlign="center">
                              {Status[order?.status || 0]}
                            </Text>
                            <Text flex={0.5} noOfLines={1} textAlign="center">
                              {totalUnits || '-'}
                            </Text>
                            <AccordionIcon />
                          </AccordionButton>
                          <AccordionPanel pb={4}>
                            <Text
                              mt="4"
                              py="2"
                              w="100%"
                              alignSelf="center"
                              textAlign="center"
                              borderRadius="5px 5px 0px 0px"
                              border="1px solid #2FB64A"
                              fontSize="14px"
                              fontWeight="bold">
                              Order summary - {(order as any)?.site?._id}
                            </Text>
                            <SimpleGrid
                              border="1px solid #2FB64A"
                              borderTop="none"
                              borderRadius="0px 0px 5px 5px"
                              alignSelf="center"
                              w="100%"
                              templateColumns={['1fr', '1fr', '2fr 1fr']}
                              columns={[1, 1, 2]}>
                              <Flex
                                flex={1}
                                direction="column"
                                borderRight={{
                                  base: 'none',
                                  md: '1px solid #2FB64A',
                                }}
                                borderBottom={{
                                  base: '1px solid #2FB64A',
                                  md: 'none',
                                }}>
                                {order?.details?.map((d, i) => {
                                  const sku = allSkus
                                    ?.find(
                                      (s) =>
                                        s.site === (order as any)?.site?._id,
                                    )
                                    ?.skus?.find((s) => s._id === d.sku);
                                  return (
                                    <Flex
                                      borderBottom={
                                        order?.details?.length === i + 1
                                          ? ''
                                          : '1px solid #2FB64A'
                                      }
                                      px="30px"
                                      py="10px"
                                      key={d._id}
                                      align="center"
                                      gap="20px">
                                      <SiDropbox size="22px" color="#2FB64A" />
                                      <Text fontWeight="bold" w="100%">
                                        {sku?.name || ''}
                                      </Text>
                                      <Text fontWeight="bold">
                                        {d.casesCount || '0'}
                                      </Text>
                                    </Flex>
                                  );
                                })}
                              </Flex>
                              <Flex
                                px="30px"
                                py="10px"
                                flexDirection={'column'}>
                                <Text fontWeight={'bold'} align="center">
                                  Notes, Questions, Feedback{' '}
                                </Text>
                                {/* )} */}
                                <Text align="center" mt={2}>
                                  {order?.notes || '-'}
                                </Text>
                              </Flex>
                            </SimpleGrid>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    );
                  },
                  renderEmpty: () => (
                    <Center p={8}>
                      <Text fontSize="20px">No Orders available</Text>
                    </Center>
                  ),
                }}
              </CustomTable>
            </Container>
          </Container>
        </>
      )}
    </Flex>
  );
};

export default OrderHistoryView;
