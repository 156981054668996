import { useQuery } from 'react-query';

import { fetchPortalDetails } from 'api/portal';

const usePortal = () => {
  const queryKey = ['portal-details'];
  const queryFn = () => fetchPortalDetails();

  const query = useQuery(queryKey, queryFn);

  return query;
};

export default usePortal;
